const MessageTopicKeys = {
	dateAvailability: 'Date availability',
	pricing: 'Pricing & packages',
	catering: 'Catering details',
	reception: 'Reception hosting',
};

export type MessageTopicKeys =
	(typeof MessageTopicKeys)[keyof typeof MessageTopicKeys];

export const messageTopicArray = Object.values(MessageTopicKeys);

const MessageToneKeys = {
	romantic: '🌹 Romantic and enthusiastic',
	friendly: '🙂 Friendly and casual',
	catering: '🏃🏽‍♀️ Direct and concise',
};

export type MessageToneKeys =
	(typeof MessageToneKeys)[keyof typeof MessageToneKeys];

export const messageToneArray = Object.values(MessageToneKeys);
