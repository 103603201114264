import { useTrackStorefrontInteraction } from '@hooks/useTrackStorefrontInteraction';
import type { Selection } from '@hooks/useTrackStorefrontInteraction';
import { transformVendorTier } from '@utils/transformVendorTier';
import { useCallback } from 'react';

export const useTrackBooking = (
	vendor: Vendor.Decorated | Vendor.Raw,
	selection: Selection,
) => {
	const trackBookingClick = useTrackStorefrontInteraction(
		'Booking',
		'Start Booking Clicked',
		{
			storefrontId: vendor.vendorId,
			vendorTier: transformVendorTier(vendor.vendorTier),
		},
	);

	return useCallback(() => {
		trackBookingClick(selection);
	}, [trackBookingClick, selection]);
};
