import { selectAssignment } from '@redux/experiments/selectors/index';
import type { VendorWidgetDirectoryAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

export const vendorWidgetDirectorySelector = (state: State) => {
	if (state.page.pageType !== 'city') {
		return null;
	}

	return selectAssignment(state, [
		'vendorWidgetDirectory',
	]) as VendorWidgetDirectoryAssignment;
};

export const isVendorWidgetDirectorySelector = (state: State) => {
	const assignment = vendorWidgetDirectorySelector(state);
	return assignment?.startsWith('variant1');
};
