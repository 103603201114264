import { StatusBadge } from '@xo-union/tk-component-badges';
import { Button } from '@xo-union/tk-component-buttons';
import { useSetAtom } from 'jotai';
import React, { useEffect, useMemo } from 'react';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../../../hooks/useTrackInlineForm/use-track-inline-form';
import { handleFieldChange } from '../../../../utils';
import TextareaField from '../../components/TextareaField/TextareaField';
import { hasGeneratedMessageAtom } from '../MessageAssistance';
import type { MessageToneKeys, MessageTopicKeys } from '../types';
import Styles from './PreviewStep.scss';
import prompts from './data/Prompts.json';

export interface PreviewStepProps {
	context: ReturnType<typeof useInlineRfqForm>;
	setGeneratedMessage: (message: string) => void;
	setInitialMessageValue: (value: string) => void;
	generatedMessage: string;
	closeMessageAssistance: () => void;
	isModalHorizontal: boolean;
	topic: MessageTopicKeys[];
	tone: MessageToneKeys;
}

const PreviewStep: React.FC<PreviewStepProps> = ({
	context,
	topic,
	tone,
	isModalHorizontal = false,
	setGeneratedMessage,
	setInitialMessageValue,
	generatedMessage,
	closeMessageAssistance,
}) => {
	const setHasGeneratedMessage = useSetAtom(hasGeneratedMessageAtom);

	const { setFieldValue, values } = context;
	const hasWeddingDate = !!values.weddingDate.value;
	const withDateAvailability = topic.includes('Date availability');

	const filteredOptions = (() => {
		const promptList =
			hasWeddingDate || !withDateAvailability
				? prompts.withWeddingDate
				: prompts.withoutWeddingDate;

		for (const prompt of promptList) {
			if (prompt.tone === tone) {
				for (const topicPrompt of prompt.topics) {
					if (topicPrompt.topic.sort().join(',') === topic.sort().join(',')) {
						return topicPrompt.options;
					}
				}
			}
		}
		return [];
	})();

	const randomOption = useMemo(() => {
		const randomIndex = Math.floor(Math.random() * filteredOptions.length);

		if (hasWeddingDate && withDateAvailability) {
			const wDate = new Date(values.weddingDate.value);
			const formattedDate = `${wDate.toLocaleString('en-EN', { month: 'long' })} ${wDate.getDate()}, ${wDate.getFullYear()}`;

			return filteredOptions[randomIndex].replace(
				'{weddingDate}',
				formattedDate,
			);
		}
		return filteredOptions[randomIndex];
	}, [
		filteredOptions,
		hasWeddingDate,
		withDateAvailability,
		values.weddingDate?.value,
	]);

	useEffect(() => {
		setGeneratedMessage?.(randomOption);
	}, [randomOption, setGeneratedMessage]);

	const trackEditMessage = useTrackInlineForm(
		'Edit AI message',
		context.initiator,
		'Review message',
	);
	const trackConfirmMessage = useTrackInlineForm(
		'Confirm AI message',
		context.initiator,
		'Review message',
	);

	return (
		<div
			className={`${Styles.previewStepContainer} ${
				isModalHorizontal
					? Styles.horizontalPreviewStepContainer
					: Styles.verticalPreviewStepContainer
			}`}
		>
			<div
				className={`${Styles.badgesContainer} ${isModalHorizontal ? Styles.horizontalBadgesContainer : ''}`}
			>
				{topic.map((topic) => (
					<StatusBadge key={topic} size="md" type="positive">
						{topic}
					</StatusBadge>
				))}
				<StatusBadge size="md" type="positive">
					{tone}
				</StatusBadge>
			</div>

			<div
				className={
					isModalHorizontal
						? Styles.horizontalTextareaContainer
						: Styles.verticalTextareaContainer
				}
			>
				<TextareaField
					context={context}
					isInPreviewStep={true}
					onBlur={() => {
						if (generatedMessage !== values.textarea.value) {
							trackEditMessage();
						}
					}}
					generatedMessage={generatedMessage}
					setGeneratedMessage={setGeneratedMessage}
					shouldShowLabel={false}
				/>
			</div>

			<div style={{ width: isModalHorizontal ? '342px' : 'auto' }}>
				<Button
					onClick={() => {
						trackConfirmMessage();
						setHasGeneratedMessage(true);
						handleFieldChange({
							cb: setFieldValue,
							data: values.textarea,
							name: 'textarea',
							value: generatedMessage,
						});
						setInitialMessageValue(generatedMessage);
						closeMessageAssistance();
					}}
					color="secondary"
					block
				>
					Use this message
				</Button>
			</div>
		</div>
	);
};

export default PreviewStep;
