import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { isRfqMessageAssistanceSelector } from '@redux/experiments/selectors/rfq-message-assistance';
import { useAppSelector } from '@redux/hooks';
import { resolveSourceContent } from '@redux/rfq/utils';
import React, { useContext, useMemo, useState, type FC } from 'react';
import { connect } from 'react-redux';
import type { Raw } from 'types/vendor';
import RecommendedContext from '../../../../contexts/RecommendedContext/RecommendedContext';
import VrmModals from '../../../VendorsSearch/containers/SearchResults/components/RFQModal/components/VrmModals/VrmModals';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import InlineRfq from '../InlineRFQ/InlineRfq';
import RfqModal from '../InlineRFQ/components/RfqModal/RfqModal';
import StickyCtaBox from '../InlineRFQ/components/StickyCtaBox';
import { useInlineRfqForm } from '../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { checkIsTablet } from '../InlineRFQ/utils';

type StateProps = ReturnType<typeof mapStateToProps>;
export type CtaWrapperProps = StateProps;

export const CtaWrapper: FC<CtaWrapperProps> = (props) => {
	const { catCode, viewport, vendorRaw } = props;
	const {
		modal: vrmModal,
		vrmOpenedFrom,
		onClose,
		recommendedVendors,
		setModal,
	} = useContext(RecommendedContext);

	const { isMobile } = viewport;
	const isTablet = checkIsTablet(viewport);
	const initiator = resolveSourceContent(isMobile, isTablet, catCode);
	const isTabletOrMobile = isTablet || isMobile;
	const isInDetailedPricing = useDetailedPricing();
	const isInBvcPlusScannability = useStorefrontFeatureScannability();
	const isLiteStorefront = useLiteStorefront();

	const formContext = useInlineRfqForm({
		initiator,
		headerText:
			isInDetailedPricing || isInBvcPlusScannability
				? 'Start the convo'
				: undefined,
	});

	const [isMessageAssistanceOpen, setIsMessageAssistanceOpen] = useState(false);
	const isRfqMessageAssistanceVariant = useAppSelector(
		isRfqMessageAssistanceSelector,
	);
	const shouldRenderMessageAssistance = useMemo(() => {
		return isRfqMessageAssistanceVariant && isMessageAssistanceOpen;
	}, [isRfqMessageAssistanceVariant, isMessageAssistanceOpen]);

	return (
		<>
			{isTabletOrMobile && !isLiteStorefront && (
				<>
					<StickyCtaBox context={formContext} />
					<RfqModal context={formContext} />
				</>
			)}
			{!isTabletOrMobile && !isLiteStorefront && (
				<InlineRfq
					context={formContext}
					isRfqMessageAssistanceVariant={isRfqMessageAssistanceVariant}
					shouldRenderMessageAssistance={shouldRenderMessageAssistance}
					setIsMessageAssistanceOpen={setIsMessageAssistanceOpen}
				/>
			)}
			{/* photo gallery opens up VRM from inside (nested), to avoid display issues */}
			{vrmModal && vrmOpenedFrom !== 'photo gallery' && (
				<VrmModals
					initiator="inline RFQ pricing top"
					modal={vrmModal}
					onClose={onClose}
					recommendedVendors={recommendedVendors}
					rfqType="inline"
					setModal={setModal}
					vendorRaw={vendorRaw}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	catCode: state.category.code,
	vendorRaw: state.vendor.vendorRaw as Raw,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(CtaWrapper);
