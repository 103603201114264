import { isRfqMessageAssistanceSelector } from '@redux/experiments/selectors/rfq-message-assistance';
import { useAppSelector } from '@redux/hooks';
import Icon, { IconButton } from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC } from 'react';
import { connect } from 'react-redux';
import type { UseInlineRfqFormReturn } from '../../hooks/useInlineRfqForm/useInlineRfqForm';
import { getMaxGuestOption } from '../../utils';
import TextareaField from '../FormBody/components/TextareaField/TextareaField';
import { TextareaFieldWithGenerateMsgBtn } from '../FormBody/components/TextareaFieldWithGenerateMsgBtn/TextareaFieldWithGenerateMsgBtn';
import Styles from './styles.scss';

type IconNames = 'email' | 'mobile' | 'date' | 'guests';

const InformationRow: FC<{
	icon: IconNames;
	text: string;
	flexibleDate?: boolean;
	shouldHaveShortText?: boolean;
}> = ({ icon, text, flexibleDate = false, shouldHaveShortText = false }) => {
	let body = <Body1 className={Styles.personalData}>{text}</Body1>;

	if (icon === 'date') {
		body = (
			<Body1 className={Styles.personalData}>
				{flexibleDate ? `${text} (flexible)` : text}
			</Body1>
		);
	}
	if (icon === 'mobile') {
		body = (
			<Body1
				className={classNames(Styles.personalData, {
					[Styles.unavailableText]: text.length === 0,
				})}
			>
				{text.length === 0 ? (
					<>
						<span className={Styles.noPhoneNumberLarge}>No phone number</span>
						<span className={Styles.noPhoneNumberShort}>n/a</span>
					</>
				) : (
					text
				)}
			</Body1>
		);
	}
	if (icon === 'email') {
		body = (
			<Body1 className={classNames(Styles.personalData, Styles.emailData)}>
				<span>{text.split('@')[0]}</span>
				<span>{`@${text.split('@')[1]}`}</span>
			</Body1>
		);
	}

	if (icon === 'guests') {
		const guestMaxOption = getMaxGuestOption();
		body = (
			<Body1 className={Styles.personalData}>
				{text === guestMaxOption.display && shouldHaveShortText
					? `${guestMaxOption.value.minGuestCount}+`
					: text}
			</Body1>
		);
	}

	return (
		<div className={Styles.informationRow}>
			<Icon name={icon} size="sm" className={Styles.icon} />
			{body}
		</div>
	);
};

export interface LiteRFQProps {
	context: UseInlineRfqFormReturn;
	generatedMessage: string;
	setInitialMessageValue?: (value: string) => void;
	setGeneratedMessage?: (message: string) => void;
	buttonHandler: () => void;
	openMessageAssistance: () => void;
}

const LiteRFQ: FC<LiteRFQProps> = (props) => {
	const {
		firstName,
		lastName,
		emailAddress,
		weddingDate,
		guestCount,
		phoneNumber,
		isDateFlexible,
	} = props.context.values;

	const isRfqMessageAssistanceVariant = useAppSelector(
		isRfqMessageAssistanceSelector,
	);

	return (
		<div>
			<div className={Styles.vendorMessage}>
				<form>
					{isRfqMessageAssistanceVariant ? (
						<TextareaFieldWithGenerateMsgBtn
							context={props.context}
							setInitialMessageValue={props.setInitialMessageValue}
							setGeneratedMessage={props.setGeneratedMessage}
							generatedMessage={props.generatedMessage}
							isOnLiteRFQ={true}
							onClick={props.openMessageAssistance}
						/>
					) : (
						<TextareaField context={props.context} isRequired />
					)}
				</form>
			</div>
			<div className={Styles.container}>
				<div className={Styles.nameButtonRow}>
					<Body1
						className={Styles.personalData}
						bold
					>{`${firstName.value} ${lastName.value}`}</Body1>
					<IconButton
						className={Styles.editIcon}
						size="sm"
						name="edit"
						onClick={props.buttonHandler}
					/>
				</div>
				<InformationRow icon="email" text={emailAddress.value} />
				<div className={Styles.twoFieldsRow}>
					<InformationRow icon="mobile" text={phoneNumber.value} />
					<InformationRow
						icon="guests"
						text={guestCount.value}
						shouldHaveShortText={phoneNumber.value.length === 0}
					/>
				</div>
				<InformationRow
					icon="date"
					text={weddingDate.value}
					flexibleDate={isDateFlexible}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	textarea: state.rfq.inline.fields.textarea,
});

export default connect(mapStateToProps)(LiteRFQ);
