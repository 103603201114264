import { estimatedPricingAssignmentSelector } from '@redux/experiments/selectors/estimated-pricing';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai/index';
import { detailedPricingUsuallySpendAtom } from '../jotai/storefront/detailed-pricing';
import { couplesUsuallySpendAtom } from '../jotai/storefront/pricing';

export const useStorefrontEstimatedPricingExperimentAssignment = () => {
	const estimatedPricingAssignment = useAppSelector(
		estimatedPricingAssignmentSelector,
	);
	const couplesUsuallySpendDetailedPricing = useAtomValue(
		detailedPricingUsuallySpendAtom,
	);
	const couplesUsuallySpend = useAtomValue(couplesUsuallySpendAtom);
	const hasCouplesUsuallySpendValue = Boolean(
		couplesUsuallySpendDetailedPricing || couplesUsuallySpend,
	);

	return hasCouplesUsuallySpendValue ? null : estimatedPricingAssignment;
};

export const useStorefrontEstimatedPricingExperiment = () => {
	const estimatedPricingAssignment =
		useStorefrontEstimatedPricingExperimentAssignment();
	return estimatedPricingAssignment === 'variant1';
};
