import { useStorefrontEstimatedPricingExperimentAssignment } from '@hooks/use-storefront-estimated-pricing-experiment';
import { reportServerSideExperiment } from '@redux/experiments';
import { liteRFQVendorWidgetAssignmentSelector } from '@redux/experiments/selectors/lite-rfq-vendor-widget';
import { newHeartAssignmentSelector } from '@redux/experiments/selectors/new-heart';
import { pvRec2point0AssignmentSelector } from '@redux/experiments/selectors/pvrec2point0';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { experiments } from '@settings';
import { Logger } from '@utils/logger/logger';
import { useCallback, useEffect } from 'react';
import type { Id } from 'types/experiments';

export const useStorefrontExperiments = () => {
	const dispatch = useAppDispatch();
	const newHeartAssignment = useAppSelector(newHeartAssignmentSelector);
	const pvRec2point0Assignment = useAppSelector(pvRec2point0AssignmentSelector);
	const liteRFQVendorWidgetAssignment = useAppSelector(
		liteRFQVendorWidgetAssignmentSelector,
	);
	const estimatedPricingAssignment =
		useStorefrontEstimatedPricingExperimentAssignment();

	const reportExperiment = useCallback(
		(experimentId: Id) => {
			dispatch(reportServerSideExperiment(experimentId));
		},
		[dispatch],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: This effect should only run once
	useEffect(() => {
		if (newHeartAssignment) {
			Logger.log({
				log_message: `New Heart Assignment: ${newHeartAssignment}`,
			});
			reportExperiment(experiments.newHeart);
		}

		if (pvRec2point0Assignment) {
			Logger.log({
				log_message: `PVRec 2.0 Assignment: ${pvRec2point0Assignment}`,
			});
			reportExperiment(experiments['pvrec2.0']);
		}

		if (liteRFQVendorWidgetAssignment) {
			Logger.log({
				log_message: `Lite RFQ Vendor Widget Assignment: ${liteRFQVendorWidgetAssignment}`,
			});
			reportExperiment(experiments.liteRFQVendorWidget);
		}

		if (estimatedPricingAssignment) {
			Logger.log({
				log_message: `Estimated Pricing Assignment: ${estimatedPricingAssignment}`,
			});
			reportExperiment(experiments.estimatedPricing);
		}
	}, []);

	return {
		newHeartAssignment,
		pvRec2point0Assignment,
		liteRFQVendorWidgetAssignment,
		estimatedPricingAssignment,
	};
};
