import { selectAssignment } from '@redux/experiments/selectors/index';
import type { EstimatedPricingAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const estimatedPricingAssignmentSelector = (state: State) => {
	const isPaid = state.vendor.vendor?.isPaid;

	if (!isPaid) return null;

	return selectAssignment(state, [
		'estimatedPricing',
	]) as EstimatedPricingAssignment;
};
