import Layout from '../pages/Layout';
import generateAsyncRouteComponent from '../utils/generateAsyncRouteComponent';

import {
	AI_USAGE_POLICY,
	BEST_OF_WEDDINGS_LEVEL,
	CATEGORY_LEVEL,
	CITY_LEVEL,
	MARKETPLACE_HOME_LEVEL,
	PHOTOS_LEVEL,
	REVIEW_WEDDING_VENDORS_LEVEL,
	STATE_LEVEL,
	STOREFRONT_LEVEL,
} from '../utils/tracking';

import { categorySlugsRegex } from '../utils/categoryNameMappings';
import { stateSlugsRegex } from '../utils/stateFooterCities';

export const stateRoute = {
	path: `/marketplace/:categorySlug(${categorySlugsRegex})/:stateSlug(${stateSlugsRegex})`,
	exact: true,
	pageType: STATE_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () => import(/* webpackChunkName: "state" */ '../pages/State'),
	}),
};

export const storefrontRoute = {
	path: '/marketplace/:storefrontName-:displayId(\\d+)',
	exact: true,
	pageType: STOREFRONT_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(
				/* webpackChunkName: "storefront" */ '../pages/Storefront/Storefront'
			),
	}),
};

export const storefrontPhotosRoute = {
	path: '/marketplace/:storefrontName-:displayId(\\d+)/photos',
	exact: true,
	pageType: PHOTOS_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(
				/* webpackChunkName: "photos" */ '../pages/Storefront/pages/StorefrontPhotos/StorefrontPhotos'
			),
	}),
};

export const citySearchRoute = {
	path: [
		`/marketplace/:categorySlug(${categorySlugsRegex})-:locationSlug/:facet?`,
		`/marketplace/q/:categorySlug(${categorySlugsRegex})/:term`,
	],
	exact: true,
	pageType: CITY_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(
				/* webpackChunkName: "city" */ '../pages/VendorsSearch/VendorSearchContainer'
			),
	}),
};

export const categoryRoute = {
	path: `/marketplace/:categorySlug(${categorySlugsRegex})`,
	exact: true,
	pageType: CATEGORY_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(/* webpackChunkName: "category" */ '../pages/Category'),
	}),
};

export const marketplaceHomeRoute = {
	path: '/marketplace',
	exact: true,
	pageType: MARKETPLACE_HOME_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(/* webpackChunkName: "marketplace-home" */ '../pages/Marketplace'),
	}),
};

export const reviewWeddingVendorsRoute1 = {
	path: '/review-wedding-vendors',
	exact: true,
	pageType: REVIEW_WEDDING_VENDORS_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(
				/* webpackChunkName: "review-wedding-vendors" */ '../pages/ReviewWeddingVendors/ReviewWeddingVendors'
			),
	}),
};

export const reviewWeddingVendorsRoute2 = {
	path: '/review-wedding-vendors/:displayId',
	exact: true,
	pageType: REVIEW_WEDDING_VENDORS_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(
				/* webpackChunkName: "review-wedding-vendors" */ '../pages/ReviewWeddingVendors/ReviewWeddingVendors'
			),
	}),
};

export const reviewWeddingVendorsRoute3 = {
	path: '/review-wedding-vendors/:displayId/:reviewId',
	exact: true,
	pageType: REVIEW_WEDDING_VENDORS_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(
				/* webpackChunkName: "review-wedding-vendors" */ '../pages/ReviewWeddingVendors/ReviewWeddingVendors'
			),
	}),
};

export const bestOfWeddingsRoute = {
	path: '/vendors/best-of-weddings',
	exact: true,
	pageType: BEST_OF_WEDDINGS_LEVEL,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(
				/* webpackChunkName: "best-of-weddings" */ '../pages/BestOfWeddings'
			),
	}),
};

export const aiUsagePolicyRoute = {
	path: '/marketplace/ai-usage-policy',
	exact: true,
	pageType: AI_USAGE_POLICY,
	component: generateAsyncRouteComponent({
		loader: () =>
			import(
				/* webpackChunkName: "ai-usage-policy" */ '../pages/AiUsagePolicy'
			),
	}),
};

export const legalAIRoute = {
	path: '/marketplace/ai-terms',
	exact: true,
	pageType: 'legal ai',
	component: generateAsyncRouteComponent({
		loader: () => import(/* webpackChunkName: "legal-ai" */ '../pages/LegalAI'),
	}),
};

export const notFoundRoute = {
	path: '*',
	pageType: 'NOT FOUND',
};

export default [
	{
		component: Layout,
		routes: [
			stateRoute,
			storefrontRoute,
			storefrontPhotosRoute,
			citySearchRoute,
			categoryRoute,
			marketplaceHomeRoute,
			reviewWeddingVendorsRoute1,
			reviewWeddingVendorsRoute2,
			reviewWeddingVendorsRoute3,
			bestOfWeddingsRoute,
			aiUsagePolicyRoute,
			legalAIRoute,
			notFoundRoute,
		],
	},
];
