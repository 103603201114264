import { useAppSelector } from '@redux/hooks';
import Icon, { IconButton } from '@xo-union/tk-component-icons';
import { Img, Picture } from '@xo-union/tk-component-picture';
import ProgressBar from '@xo-union/tk-component-progress-bar';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { atom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import type { useInlineRfqForm } from '../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../../hooks/useTrackInlineForm/use-track-inline-form';
import { handleFieldChange } from '../../../utils';
import Styles from './MessageAssistance.scss';
import PreferencesStep from './components/PreferencesStep';
import PreviewStep from './components/PreviewStep';
import Subheader from './components/Subheader';
import type { MessageToneKeys, MessageTopicKeys } from './types';

export const hasGeneratedMessageAtom = atom<boolean>(false);

interface MessageAssistanceProps {
	isModalOpen: boolean;
	isModalHorizontal: boolean;
	setGeneratedMessage: (message: string) => void;
	generatedMessage: string;
	closeMessageAssistance: () => void;
	context: ReturnType<typeof useInlineRfqForm>;
	initialMessageValue?: string;
	setInitialMessageValue: (value: string) => void;
}

const Header = ({
	context,
	step,
	closeMessageAssistance,
	setGeneratedMessage,
	isModalOpen,
	initialMessageValue,
}: {
	context: ReturnType<typeof useInlineRfqForm>;
	step: number;
	closeMessageAssistance: () => void;
	setGeneratedMessage: (message: string) => void;
	isModalOpen: boolean;
	initialMessageValue?: string;
}) => {
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const { setFieldValue, values } = context;

	const trackCancelClick = useTrackInlineForm(
		'Cancel AI message',
		context.initiator,
		step === 2 ? 'Review message' : 'Prompt selection',
	);

	const restoreInitialMessageValue = () => {
		handleFieldChange({
			cb: setFieldValue,
			data: values.textarea,
			name: 'textarea',
			value: initialMessageValue || '',
		});
	};

	return (
		<div className={Styles.header}>
			{isModalOpen && (
				<DisplayButton
					type="button"
					onClick={() => {
						restoreInitialMessageValue();
						trackCancelClick();
						closeMessageAssistance();
					}}
					color="primary"
				>
					<Icon
						className={Styles.backButtonCaret}
						name="caret_left"
						size="sm"
					/>

					<span>Cancel{!isMobile && ' and go back'}</span>
				</DisplayButton>
			)}
			<div
				className={classNames(Styles.progress, {
					[Styles.onModal]: isModalOpen,
				})}
			>
				<ProgressBar progress={step / 2} />
				<div>{step}/2</div>
			</div>
			{!isModalOpen && (
				<IconButton
					name="close"
					size="md"
					block
					aria-label="close"
					onClick={() => {
						restoreInitialMessageValue();
						closeMessageAssistance();
					}}
				/>
			)}
		</div>
	);
};

const MessageAssistance: React.FC<MessageAssistanceProps> = ({
	isModalHorizontal = false,
	isModalOpen,
	setGeneratedMessage,
	generatedMessage,
	closeMessageAssistance,
	context,
	initialMessageValue,
	setInitialMessageValue,
}) => {
	const [step, setStep] = useState<number>(1);
	const [topic, setTopic] = useState<MessageTopicKeys[]>([]);
	const [tone, setTone] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (containerRef.current) {
			const modalRef = containerRef.current.closest('[role="dialog"]');

			if (modalRef) {
				modalRef.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		}
	}, []);

	const handlePreferencesChange = (
		topicClicked: boolean,
		value: MessageTopicKeys | MessageToneKeys,
	) => {
		if (topicClicked) {
			if (topic.includes(value)) {
				setTopic(topic.filter((item) => item !== value));
			} else {
				setTopic([...topic, value]);
				setTone('');
			}
		} else {
			setTone(value === tone ? '' : value);
		}
	};

	return (
		<div
			className={`${Styles.container} ${isModalHorizontal ? Styles.horizontalContainer : ''}`}
			ref={containerRef}
		>
			<Header
				context={context}
				step={step}
				closeMessageAssistance={closeMessageAssistance}
				setGeneratedMessage={setGeneratedMessage}
				isModalOpen={isModalOpen}
				initialMessageValue={initialMessageValue}
			/>
			{isLoading && (
				<div className={Styles.animationContainer}>
					<Picture
						env="production"
						id={'0e15b699-9dd4-4a93-996f-046a37666643'} // bouncing 'K' gif
					>
						<Img alt="Loading animation" />
					</Picture>
					<Body1 bold>Writing message...</Body1>
				</div>
			)}
			{!isLoading && (
				<div className={Styles.content}>
					<Subheader
						step={step}
						isModalHorizontal={isModalHorizontal}
						topic={topic}
						tone={tone}
					/>
					{step === 1 && (
						<PreferencesStep
							context={context}
							topic={topic}
							tone={tone}
							isModalHorizontal={isModalHorizontal}
							handlePreferencesChange={handlePreferencesChange}
							setIsLoading={setIsLoading}
							setStep={setStep}
						/>
					)}
					{step === 2 && (
						<PreviewStep
							context={context}
							setInitialMessageValue={setInitialMessageValue}
							setGeneratedMessage={setGeneratedMessage}
							generatedMessage={generatedMessage}
							closeMessageAssistance={closeMessageAssistance}
							isModalHorizontal={isModalHorizontal}
							topic={topic}
							tone={tone}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default MessageAssistance;
