import { atom, type createStore } from 'jotai/vanilla';

export interface DetailedPricingPackage {
	title: string;
	price: string;
	serviceDuration: string;
	services: string[];
}

export interface DetailedPricingState {
	yearsInBusiness: string;
	totalMembers: string;
	languages: string;
	startingCost: string;
	usuallySpend: string;
	videographyAvailable: boolean;
	packages: DetailedPricingPackage[];
	estimateUsuallySpend: string | null | undefined;
}

const initialState: DetailedPricingState = {
	yearsInBusiness: '',
	totalMembers: '',
	languages: '',
	startingCost: '',
	usuallySpend: '',
	videographyAvailable: false,
	packages: [],
	estimateUsuallySpend: '',
};

export const yearsInBusinessAtom = atom(initialState.yearsInBusiness);
export const totalMembersAtom = atom(initialState.totalMembers);
export const languagesAtom = atom(initialState.languages);
export const detailedPricingStartingCostAtom = atom(initialState.startingCost);
export const detailedPricingUsuallySpendAtom = atom(initialState.usuallySpend);
export const videographyAvailableAtom = atom(initialState.videographyAvailable);
export const packagesAtom = atom(initialState.packages);
export const hasStartingCostAtom = atom((get) => {
	const startingCost = get(detailedPricingStartingCostAtom);
	return startingCost !== '';
});
export const estimateUsuallySpendAtom = atom(initialState.estimateUsuallySpend);

export const getDetailedPricingDataToHydrate = (
	store: ReturnType<typeof createStore>,
) => {
	return {
		yearsInBusiness: store.get(yearsInBusinessAtom),
		totalMembers: store.get(totalMembersAtom),
		languages: store.get(languagesAtom),
		startingCost: store.get(detailedPricingStartingCostAtom),
		usuallySpend: store.get(detailedPricingUsuallySpendAtom),
		videographyAvailable: store.get(videographyAvailableAtom),
		packages: store.get(packagesAtom),
		estimateUsuallySpend: store.get(estimateUsuallySpendAtom),
	};
};

export const getDetailedPricingInitialValues = (
	values: ReturnType<typeof getDetailedPricingDataToHydrate>,
) => {
	const {
		yearsInBusiness,
		totalMembers,
		languages,
		startingCost,
		usuallySpend,
		videographyAvailable,
		packages,
		estimateUsuallySpend,
	} = values || {};
	return [
		[yearsInBusinessAtom, yearsInBusiness || initialState.yearsInBusiness],
		[totalMembersAtom, totalMembers || initialState.totalMembers],
		[languagesAtom, languages || initialState.languages],
		[
			detailedPricingStartingCostAtom,
			startingCost || initialState.startingCost,
		],
		[
			detailedPricingUsuallySpendAtom,
			usuallySpend || initialState.usuallySpend,
		],
		[
			videographyAvailableAtom,
			videographyAvailable || initialState.videographyAvailable,
		],
		[packagesAtom, packages || initialState.packages],
		[
			estimateUsuallySpendAtom,
			estimateUsuallySpend || initialState.estimateUsuallySpend,
		],
	];
};
