import { Button } from '@xo-union/tk-component-buttons';
import React from 'react';

import { useAppSelector } from '@redux/hooks';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../../../hooks/useTrackInlineForm/use-track-inline-form';
import {
	type MessageToneKeys,
	type MessageTopicKeys,
	messageToneArray,
	messageTopicArray,
} from '../types';
import Styles from './PreferencesStep.scss';

const Pill = ({
	context,
	handleClick,
	option,
	isMobile,
	selectedValue,
}: {
	context: ReturnType<typeof useInlineRfqForm>;
	handleClick: (value: MessageTopicKeys | MessageToneKeys) => void;
	option: MessageTopicKeys | MessageToneKeys;
	isMobile: boolean;
	selectedValue: MessageTopicKeys[] | MessageToneKeys | '';
}) => {
	const track = useTrackInlineForm(
		option,
		context.initiator,
		'Prompt selection',
	);

	return (
		<Button
			className={
				selectedValue === option ||
				(Array.isArray(selectedValue) && selectedValue.includes(option))
					? Styles.option
					: Styles.nonSelectedOption
			}
			color="secondary-alternative"
			size={isMobile ? 'sm' : 'md'}
			onClick={() => {
				track();
				handleClick(option);
			}}
		>
			{option}
		</Button>
	);
};

interface PreferencesPickerProps {
	context: ReturnType<typeof useInlineRfqForm>;
	description: string;
	options: MessageTopicKeys[] | MessageToneKeys[];
	isMobile: boolean;
	selectedValue: MessageTopicKeys[] | MessageToneKeys | '';
	handleClick: (value: MessageTopicKeys | MessageToneKeys) => void;
}

const PreferencesPicker = ({
	context,
	description,
	options,
	isMobile,
	selectedValue,
	handleClick,
}: PreferencesPickerProps) => {
	return (
		<div className={Styles.pickerContent}>
			<div>{description}</div>
			<div
				className={`${Styles.pickerOptionsContainer} ${isMobile ? Styles.mobilePicker : ''}`}
			>
				{options.map((option) => (
					<Pill
						key={option}
						context={context}
						isMobile={isMobile}
						option={option}
						selectedValue={selectedValue}
						handleClick={handleClick}
					/>
				))}
			</div>
		</div>
	);
};

interface PreferencesStepProps {
	context: ReturnType<typeof useInlineRfqForm>;
	topic: MessageTopicKeys[];
	tone: MessageToneKeys;
	isModalHorizontal: boolean;
	handlePreferencesChange: (topic: boolean, value: string) => void;
	setIsLoading: (isLoading: boolean) => void;
	setStep: (step: number) => void;
}

const PreferencesStep = ({
	context,
	topic,
	tone,
	isModalHorizontal,
	handlePreferencesChange,
	setIsLoading,
	setStep,
}: PreferencesStepProps) => {
	const isMobile = useAppSelector((state) => state.viewport.isMobile);

	const trackCreateMessage = useTrackInlineForm(
		'Create AI message',
		context.initiator,
		'Prompt selection',
	);

	return (
		<>
			<div className={Styles.bodyContainer}>
				<div
					className={`${Styles.pickersContainer} ${isModalHorizontal ? Styles.horizontalPickersContainer : ''}`}
				>
					<div className={Styles.topicContainer}>
						<PreferencesPicker
							context={context}
							description="I'd like to ask the venue about..."
							options={messageTopicArray}
							isMobile={isMobile}
							selectedValue={topic}
							handleClick={(value: string) =>
								handlePreferencesChange(true, value)
							}
						/>
					</div>
					<div className={Styles.toneContainer}>
						<PreferencesPicker
							context={context}
							description="Choose your message tone"
							options={messageToneArray}
							isMobile={isMobile}
							selectedValue={tone}
							handleClick={(value: string) =>
								handlePreferencesChange(false, value)
							}
						/>
					</div>
				</div>
			</div>
			<div
				className={isModalHorizontal ? Styles.horizontalButtonContainer : ''}
			>
				<Button
					disabled={!(topic.length > 0) || !tone}
					onClick={() => {
						setIsLoading(true);
						trackCreateMessage();
						setTimeout(() => {
							setIsLoading(false);
							setStep(2);
						}, 3000);
					}}
					block
					color="secondary"
				>
					Create
				</Button>
			</div>
		</>
	);
};

export default PreferencesStep;
