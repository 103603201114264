import { generateExperimentHeader } from '@server/methods/search/utils';
import { fetchPvRecAnon } from '../../../../api/pvRecAnon';
import pvRecCrossCategoryQuery from '../../../graphql_queries/pvrec_cross_category';
import similarQuery from '../../../graphql_queries/similar';
import { setMaxResultColumns } from '../../../redux/search';
import { requestAnonymousId } from '../../../redux/settings';
import { fetchPvRecAnonSuccess } from '../../../redux/widget/actions';
import constants from '../constants';
import * as Settings from '../settings';

export function fetchSimilarVendors(
	vendor,
	limit = constants.DEFAULT_VENDOR_LIMIT,
	radius,
	location,
	version,
) {
	return {
		type: constants.FETCH_SIMILAR_VENDORS,
		request: {
			url: Settings.marketplaceGraphqlUrl,
			method: 'POST',
			data: {
				query: similarQuery,
				variables: {
					id: vendor.id,
					limit,
					radius,
					location,
					version,
				},
			},
		},
	};
}

export function fetchPvRecAnonVendors(categoryCode, location, marketCode) {
	return async (/** @type {Redux.Dispatch} */ dispatch, getState) => {
		dispatch({
			type: constants.FETCH_PVREC_ANON_VENDORS,
		});
		try {
			await dispatch(requestAnonymousId());
			const {
				experiments,
				settings: { anonymousId },
			} = getState();
			const experimentHeaders = generateExperimentHeader(experiments);
			const response = await fetchPvRecAnon(
				categoryCode,
				location,
				marketCode,
				constants.DEFAULT_VENDOR_LIMIT_FETCH,
				anonymousId,
				experimentHeaders,
			);

			if (response.profiles?.length > 0) {
				dispatch(fetchPvRecAnonSuccess(response));
			} else {
				// If there are no PVRec results, we hide the right rail on search pages
				dispatch(setMaxResultColumns(4));
			}
		} catch (e) {
			dispatch(setMaxResultColumns(4));
		}
	};
}

export function fetchPvRecCrossCatVendors(
	vendorId,
	categoryCodes,
	pvRec2point0Assignment,
) {
	return {
		type: constants.FETCH_PVREC_CROSS_CAT_VENDORS,
		contentType: 'application/json',
		request: {
			url: `${Settings.marketplaceGraphqlUrl}`,
			method: 'POST',
			data: {
				query: pvRecCrossCategoryQuery,
				variables: {
					id: vendorId,
					categoryCodes,
					limit: constants.DEFAULT_VENDOR_LIMIT_FETCH,
					apiKey: Settings.pvRecApiKey,
					version: pvRec2point0Assignment === 'variant1' ? '1' : '0',
				},
			},
		},
	};
}

export { default as fetchBowVendors } from './fetch-bow-vendors';
