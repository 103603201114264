import { Body1, Editorial } from '@xo-union/tk-ui-typography';
import React from 'react';
import type { MessageToneKeys, MessageTopicKeys } from '../types';
import Styles from './Subheader.scss';

interface SubHeaderProps {
	step: number;
	isModalHorizontal: boolean;
	topic?: MessageTopicKeys[];
	tone?: MessageToneKeys;
}

const Subheader = ({
	step,
	isModalHorizontal,
	topic,
	tone,
}: SubHeaderProps) => {
	return (
		<div
			className={`${Styles.container} ${isModalHorizontal ? Styles.horizontalContainer : ''}`}
		>
			<Editorial bold>
				{step === 1 ? 'Create your message' : 'Review your message'}
			</Editorial>
			<Body1>
				{step === 1
					? "Pick your details, and we'll handle the rest."
					: 'Check it out and make edits if needed.'}
			</Body1>
		</div>
	);
};

export default Subheader;
