export const BOW_HOF_IMAGES = {
	BOW2025: {
		id: '9f56c034-2057-4a02-99ee-18cc8c516ba7',
		name: '2025 Best of Weddings Winner',
	},
	BOW2024: {
		id: 'd15e4891-5b15-4e3e-826e-623b5df9b552',
		name: '2024 Best of Weddings Winner',
	},
	BOW2023: {
		id: '94e45e64-70d0-457b-9b96-755126c12240',
		name: '2023 Best of Weddings Winner',
	},
	BOW2022: {
		id: 'f3a072b5-7d4c-4c34-94ac-88e5a59c8353',
		name: '2022 Best of Weddings Winner',
	},
	BOW2021: {
		id: 'a19f4869-0b15-4030-8e8d-6e3f2cb3f75b',
		name: '2021 Best of Weddings Winner',
	},
	BOW2020: {
		id: 'f1b6c328-9085-419e-a565-e18c384afb34',
		name: '2020 Best of Weddings Winner',
	},
	BOW2019: {
		id: '456d566a-a6ca-475f-8f5a-93e45cc7e81d',
		name: '2019 Best of Weddings Winner',
	},
	BOW2018: {
		id: 'c684fd08-899d-4324-9828-ed231612ad3e',
		name: '2018 Best of Weddings Winner',
	},
	BOW2017: {
		id: 'cf805d40-a376-487a-b64d-0a02ca20f736',
		name: '2017 Best of Weddings Winner',
	},
	BOW2016: {
		id: '8e37b91d-2fde-4bb6-b113-3e53ec30901d',
		name: '2016 Best of Weddings Winner',
	},
	BOW2015: {
		id: 'f5a78365-fb2f-42e0-9ebc-b20b45498df1',
		name: '2015 Best of Weddings Winner',
	},
	BOW2014: {
		id: '824ddd67-fb0e-4a26-92a4-d4996b0d2b23',
		name: '2014 Best of Weddings Winner',
	},
	BOW2013: {
		id: '771ca283-cad1-47cb-9d5f-94e8c360e3be',
		name: '2013 Best of Weddings Winner',
	},
	BOW2012: {
		id: 'd5539e19-8bbd-4a86-b4c8-d7be04c6c773',
		name: '2012 Best of Weddings Winner',
	},
	BOW2011: {
		id: '7504f472-fbdf-4115-a317-0a8fdec1b4a5',
		name: '2011 Best of Weddings Winner',
	},
	BOW2010: {
		id: 'e1eec2fa-862e-440c-a4af-88efcd21e112',
		name: '2010 Best of Weddings Winner',
	},
	'BOW2008-09': {
		id: 'aebf0cfd-749a-4015-9cf0-6e3c13a96bd2',
		name: '2009 Best of Weddings Winner',
	},
	BOW2008: {
		id: '78e6243b-7c04-492a-ac8f-1f4933dd2048',
		name: '2008 Best of Weddings Winner',
	},
	BOW2007: {
		id: '8a61e330-1677-498d-b00a-94b0759eb63a',
		name: '2007 Best of Weddings Winner',
	},
	BOWHOF: {
		id: '6738d190-bb59-4af8-bbe3-df752b1f6f51',
		name: 'Hall of Fame',
	},
};
